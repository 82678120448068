<template>
  <Layout :footer-style="1">
    <div class="container">
      <Breadcrumb root-url="/security-privacy" parent-url="安全隐私" current-url="堡垒机" style="border: none;"/>
    </div>

    <div class="rn-service-area rn-section-gapBottom pt--100 bg-color-light area-2">
      <div class="container">
        <div class="product">
          <img class="pic" src="@/assets/images/product/img_3.png" />
          <div class="about">
            <div class="title">堡垒机</div>
            <div class="content">
              堡垒机，即在一个特定的网络环境下，为了保障网络和数据不受入侵和破坏，运用各种技术手段对网络内的服务器、网络设备、安全设备、数据库等设备的操作行为，以便于集中报警、及时处理及审计定责。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gapBottom pt--60 area-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title" data-aos="fade-up">
              <h3 class="title color-gray">JumpServer</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-37.png">
            <div class="company-info">
              JumpServer开源堡垒机采用了分布式架构设计，采用容器化的部署方式，支持多种资产类型和大规模资产纳管，可以支持高并发访问，满足企业用户在混合IT环境中的运维安全审计需求。JumpServer的用户涵盖金融、制造、物流、媒体、互联网等行业。
            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">安恒</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-38.png">
            <div class="company-info">
              安恒信息以云安全、大数据安全、物联网安全、智慧城市安全、工业控制系统安全及工业互联网安全五大方向为市场战略。凭借研发实力和持续的产品创新，已形成覆盖网络信息安全生命全周期的产品体系，包括网络信息安全基础产品、网络信息安全平台以及网络信息安全服务，各产品线及业务线在行业中均形成竞争力( [49])。2022年，科创板开市三周年之际公布的《2022科创板创新力榜单》中，安恒信息入选并成为唯一一支网络安全股。( [50])安恒信息已形成完善的网络安全、数据安全、商用密码应用等综合信创解决方案。
            </div>
          </div>
        </div>
        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">齐治堡垒机</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-39.png">
            <div class="company-info">
              齐治CEO在负责国际知名公司的网络运维工作中,由于运维人员众多，人员身份复杂发生故障时，无法及时确认引发故障的原因及操作人员，开始从事安全运维审计产品的设计与研发工作。2005年研发出世界上第一台堡垒机，并在高端用户处正式使用，因堡垒机成立的齐治科技，自成立至今，只做堡垒机一个产品。经过众多高端用户实际应用环境的锤炼，在8年中积累了大量的行业应用，获得了丰富的经验教训，成为本行业当知无愧，最专业、最权威的公司。            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- End Service Area -->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Icon from '@/components/icon/Icon'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
export default {
  name: 'Product',
  components: {
    SectionTitle,
    Separator,
    Icon,
    Breadcrumb,
    Layout
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.display-block{
  display: block;
}
.area-2{
  .icon {
    .p-img {
      width: 64px;
      height: 64px;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
.product{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .pic{
    height: 220px;
  }
  .about{
    margin-left: 40px;
    color: #000000;
    .title{
      font-size: 32px;
      font-weight: bold;
    }
    .content{
      margin-top: 12px;
      font-size: 20px;
    }
  }
}
.company-logo{
  width: 320px;
}
.company-info{
  font-size: 24px;
  color: #000000;
  text-align: left;
  margin-top: 20px;
}
</style>
